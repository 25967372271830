window.myCollect = new window.Collect({
    org_id: 'orgef658d45978edf23d59dd05a964e4237',
    event_props: {
        source: '应用广场',//来源信息
    },//【非必填】
    userFlag: {
        PhoneNumber: '',//手机号
        Email: '',//邮箱
    }//【非必填】，预置用户标识,需用户手动设置。
});
//自动埋点开启
window.myCollect.init({
    page_view_auto: true,//页面浏览自动上报开关，默认开启true
    element_click_auto: true,//点击事件自动上报开关，默认关闭false。
});

var _hmt = _hmt || [];
(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?91c7482d6fad04fc0f2148954d78ed40";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();
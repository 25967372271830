import {
    can_trial,
    // checkLoginStatus,
    getAppList,
    // getEnterpriseList,
    getEnterpriseLoginUrl,
    // getLoginStatus,
    // getTenantPermission,
    // getUserInfo,
    trial,
} from '../api'

import {
    setPayModalAppId,
    setPayModalAppList,
    // setPayModalCategories,
    setPayModalLoading,
    setPayModalOrderType,
    setPayModalVisible, setRecordModalInitialState, setRecordModalShow, setRecordModalShowExtra,
    // setRecordModalInitialState,
    // setRecordModalShow,
    // setRecordModalShowExtra,
} from '../store'

import {showAdminPermissionModal} from "../components/AdminPermissionModal.jsx";
import {ENTERPRISE_BASE_URL} from "../constants";

const getOperatePermission = (initialState) => {
    if (initialState?.staff && !initialState.staff.is_super_admin) {
        showAdminPermissionModal(initialState.admin_name)
        return false
    }
    return true
}

export function goLogin(base_url) {
    window.location.href = `${base_url}/enuser/login?callback=${encodeURIComponent(
        window?.location?.href
    )}`
}

/**
 *  产品跳转处理
 * @param product_key
 * @param type
 * @param self
 */
export async function toProductDetail(product_key, type, self) {
    const {data} = await getEnterpriseLoginUrl({
        values: {product_key},
    })
    if (data.url) {
        if (!type) return window.open(data.url, self ? '_self' : '_blank')
        const currentUrl = new URL(data.url)
        currentUrl.searchParams.append('type', type)
        window.open(currentUrl.toString(), self ? '_self' : '_blank')
    }
}

/**
 *  产品试用
 * @param product_key
 * @param type
 * @param self
 */
export async function toProductTrial(product_key, type, self) {
    await trial({
        values: {
            product_key,
        },
    })
    await toProductDetail(product_key, type, self)
}

export const handlePay = async (
    type,
    initialState,
    dispatch,
    id
) => {
    if (!getOperatePermission(initialState)) return
    dispatch(setPayModalAppId(id || ""))
    setTimeout(() => {
        dispatch(setPayModalVisible(true))
        dispatch(setPayModalOrderType(type))
    })
}

// 接待工作台订购
export const toOrder = (
    product_key,
    initialState,
    dispatch,
    type,
    self
) => {
    if (!getOperatePermission(initialState)) return
    console.log("xxxx")
    dispatch(setPayModalLoading(true))
    toProductDetail(product_key, type, self)
        .then(() => {
            // window.location.reload()
        })
        .finally(() => {
            dispatch(setPayModalLoading(false))
        })
}

// 去留资
export const toRecordInfo = (
    type,
    initialState,
    dispatch,
    source = '应用广场'
) => {
    if (!initialState?.loginStatus) {
        return goLogin(ENTERPRISE_BASE_URL)
    }
    dispatch(setRecordModalInitialState(initialState))
    dispatch(setRecordModalShow(true))
    dispatch(
        setRecordModalShowExtra({
            source,
            editType: type,
        })
    )
}
export const toTrial = async (
    product_key,
    initialState,
    dispatch,
    type,
    self,
    notReload
) => {
    if (!getOperatePermission(initialState)) return
    dispatch(setPayModalLoading(true))
    const result = await can_trial({
        values: {
            product_key,
        },
    })
    if (result?.can_trial) {
        try {
            await toProductTrial(product_key, type, self)
            const {products: appList} = await getAppList()
            dispatch(setPayModalAppList(appList))
            dispatch(setPayModalLoading(false))
            if (!notReload) {
                window.location.reload()
            }
        } finally {
            dispatch(setPayModalLoading(false))
        }
    } else {
        toProductDetail(product_key).finally(() => dispatch(setPayModalLoading(false)))
    }
}

export function getCurrentKey(path) {
    // 移除查询字符串（如果有）
    const urlWithoutQuery = path.split('?')[0];

    // 定义匹配模式，寻找 /app/detail/ 后面紧跟着的非斜杠字符序列
    const regex = /\/app\/detail\/([^\/]+)/;
    const match = urlWithoutQuery.match(regex);

    let result = null;

    if (match) {
        // 提取到的目标字段
        const field = match[1];

        // 按照 __ 分割字段
        const parts = field.split('__');

        // 如果存在 __，则分割为左右两部分；否则右侧部分为 null
        result = parts.length > 1 ? {
            product_key: parts[0].replace(/^app_/, ""),
            app_key: parts.slice(1).join('__')
        } : {product_key: field.replace(/^app_/, ""), app_key: null};
    }

    return result;
}

export function getPaidInfo() {
    const info = {}
    const fields = ["paid_app_id", "paid_source_type"]
    for (const field of fields) {
        info[field] = localStorage.getItem(field)
        localStorage.removeItem(field)
    }
    return info
}



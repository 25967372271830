const {Input} = antd
const {useState,useEffect} = React
function MobileSearchInput({load=false}) {
    const [input,setInput] = useState("")

    useEffect(()=>{
        if (load){
            const searchWord = localStorage.getItem("word")
            setInput(searchWord)
        }
    },[])
    const handlePressEnter = ()=>{
        window.location.href = window.location.origin + `/app/search?word=${input}`
    }
    return (
        <Input style={{borderRadius:20,height:40,width:336,}} styles={{input:{height:30,fontSize:14,lineHeight:"22px"}}} placeholder="请输入应用名称" prefix={<img width={18} height={18} src="https://cdn-static.xiaoduoai.com/apphub/bitable/9900e1fd21842237d2858ff3f92503b9.png" alt="search-icon"/>} value={input} onChange={(e)=>setInput(e.target.value)} onPressEnter={handlePressEnter}/>
    );
}

export default MobileSearchInput;
import {useSelect} from '../hooks/useSelect';
import {
    selectImageViewer,
    selectImageViewerCurrentIndex,
    setImageViewerList,
    setImageViewerListCurrentItem,
    setImageViewerListPcVisible,
    setImageViewerListVisible,
} from '../store';
import {useDispatch} from '../hooks/useReduxStore';

const {useState, useEffect, useRef} = React
const MyImageViewer = () => {
    const dispatch = useDispatch();
    const imageViewer = useSelect(selectImageViewer);
    const currentImageIndex = useSelect(selectImageViewerCurrentIndex);
    const containerRef = useRef(null);
    const viewerInstance = useRef(null);
    const [imageUrls,setImageUrls] = useState([])

    useEffect(() => {
        const images = Array.from(document.querySelectorAll('.app-hub-image-viewer-item')) || [];
        const urls = images.map((img) => img.src); // 收集所有图片的 src
        const initList = Array.from(new Set(urls))
        setImageUrls(initList)
        dispatch(setImageViewerList(initList));
    }, []);

    useEffect(() => {
        const images = Array.from(document.querySelectorAll('.app-hub-image-viewer-item'));

        const handleClick = (event) => {
            dispatch(setImageViewerListCurrentItem(event.target.src));
            dispatch(setImageViewerListVisible(true)); // 打开 Viewer
        };

        // 绑定点击事件
        images.forEach((img) => img.addEventListener('click', handleClick));

        // 清理点击事件
        return () => {
            images.forEach((img) => img.removeEventListener('click', handleClick));
        };
    }, [dispatch]);
    useEffect(() => {
        if (containerRef.current) {
            // 初始化 Viewer.js
            viewerInstance.current = new Viewer(containerRef.current, {
                inline: false,
                initialViewIndex: currentImageIndex,
                hidden: () => {
                    // 检查是否是手动关闭 Viewer
                    if (imageViewer.visible) {
                        dispatch(setImageViewerListVisible(false));
                    }
                },
                viewed: () => {
                    // 更新当前查看的图片索引
                    const currentIndex = viewerInstance.current?.index ?? 0;
                    dispatch(setImageViewerListCurrentItem(imageUrls[currentIndex]));
                },
            });
        }
        return () => {
            // 清理 Viewer.js 实例
            if (!imageViewer.visible){
                viewerInstance.current?.destroy();
            }
        };
    }, [currentImageIndex, imageUrls,imageViewer.visible]);

    useEffect(() => {
        // 打开或关闭 Viewer.js
        if (imageViewer.visible) {
            viewerInstance.current?.show();
        } else {
            viewerInstance.current?.hide();
        }
    }, [imageViewer.visible]);

    return (
        <div style={{display: 'none'}} ref={containerRef}>
            {imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Image ${index}`}/>
            ))}
        </div>
    );
};

export default MyImageViewer
import store from '../store'

const {useEffect, useState} = React

export const useSelect = (selector) => {
    const [selectedState, setSelectedState] = useState(() => selector(store.getState()))

    useEffect(() => {
        const handleStateChange = () => {
            const updatedState = selector(store.getState())
            setSelectedState(updatedState)
        }
        const unsubscribe = store.subscribe(handleStateChange)
        return () => unsubscribe()
    }, [selector])

    return selectedState
}

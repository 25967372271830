import {getAppList} from "./api";

window.addEventListener('DOMContentLoaded', async (event) => {
    try {
        const {products: allProducts} = await getAppList()
        const product_cards = document.querySelectorAll(".product_card[data-app_key]")
        const map = new Map()
        if (allProducts) {
            for (const product_item of allProducts) {
                const product = product_item?.product
                const app_instance = product_item?.app_instance
                map.set(product.product_key, !(product.status === 0 || product.status === 2))
                const plugins = app_instance?.plugins
                if (plugins) {
                    for (const plugin of plugins) {
                        map.set(plugin.plugin_key, !(plugin.order_status === 0 || plugin.order_status === 2))
                    }
                }
            }
        }
        for (const product_card of product_cards) {
            const flag = map.get(product_card?.dataset?.app_key)
            if (flag) {
                const productCard = product_card?.querySelector(".product_card_status");
                if (productCard) {
                    productCard.style.display = "block";
                }
            }
        }

        // 详情页状态
        const detail_card = document.querySelector(".detail_app_hub_product_main[data-app_key]")
        const flag = map.get(detail_card?.dataset?.app_key)
        if (flag) {
            const detail_card_status_title = detail_card?.querySelector(".detail_app_hub_product_main_top_right_title_status");
            const detail_card_status = detail_card?.querySelector(".detail_app_hub_product_main_top_right_status");
            if (detail_card_status) {
                detail_card_status.style.display = "inline-block";
            }
            if (detail_card_status_title){
                detail_card_status_title.style.display = "block";
            }
        }

    }catch (e) {
        console.warn(e)
    }
})
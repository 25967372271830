import {ENTERPRISE_BASE_URL} from "./constants";
import {getPaidInfo} from "./utils";
import {getAppInstance, getEnterpriseLoginUrl, getPlugin} from "./api";

const {Button, QRCode} = antd;

const {useState, useEffect} = React

function Paid(props) {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [appUrl, setAppUrl] = useState("")
    const [isExternal, setIsExternal] = useState(false)
    const [qrCodeUrl, setQrCodeUrl] = useState("https://work.weixin.qq.com/ct/wcde16c63fec51e1b6d7ad16f4bd3970d7f6")
    const [qrCodeIcon, setQrCodeIcon] = useState("https://cdn-static.xiaoduoai.com/apphub/bitable/5e9159dff1b0251e5b113c2dda6ce19f.png")

    useEffect(() => {
        const checkScreenWidth = () => {
            if (window.innerWidth < 992) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        checkScreenWidth();
        window.addEventListener('resize', checkScreenWidth);
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, [])

    useEffect(() => {
        const {paid_app_id, paid_source_type} = getPaidInfo()
        ;(async () => {
            if (paid_source_type === "0") {
                const appInstance = await getAppInstance({
                    values: {
                        id: paid_app_id
                    }
                })
                console.log(appInstance)
                if (appInstance.is_external) {
                    setIsExternal(true)
                    appInstance?.qr_code_url && setQrCodeUrl(appInstance.qr_code_url)
                    appInstance?.qr_code_icon && setQrCodeIcon(appInstance.qr_code_icon)
                } else {
                    getEnterpriseLoginUrl({
                        values: {
                            product_key: appInstance.product_key,
                        },
                    }).then((result) => {
                        setAppUrl(result?.data?.url)
                    })
                    // setAppUrl(`/app/detail/app_${appInstance.product_key}`)
                }
            } else {
                const plugin = await getPlugin({
                    values: {
                        id: paid_app_id
                    }
                })
                const parentAppInstance = await getAppInstance({
                    values: {
                        id: plugin.app_instance_id
                    }
                })
                console.log(plugin)
                if (plugin.is_external) {
                    setIsExternal(true)
                    plugin?.qr_code_url && setQrCodeUrl(plugin.qr_code_url)
                    plugin?.qr_code_icon && setQrCodeIcon(plugin.qr_code_icon)
                } else {
                    getEnterpriseLoginUrl({
                        values: {
                            product_key: parentAppInstance.product_key,
                        },
                    }).then((result) => {
                        setAppUrl(result?.data?.url)
                    })
                }
            }
        })()
    }, []);
    return (
        <div className="paid_container">
            <img className="paid_logo"
                 src="https://cdn-static.xiaoduoai.com/apphub/bitable/bd38dc49323c40c3b5b43ab5a78b00f3.png"
                 alt="订购成功"/>
            <span className="paid_title">订购成功</span>
            <span className="paid_description">您的应用订单已成功添加至晓总管
                <a href={`${ENTERPRISE_BASE_URL}/account/orders/orderManage`} className="paid_description_link"
                   target="_blank">订单管理</a>中
            </span>
            {
                isExternal ? (
                    <div className="paid_qrcode">
                        <div className="paid_qrcode_bg">
                            <QRCode
                                value={qrCodeUrl}
                                size={isSmallScreen ? 96 : 124}
                                bgColor="#FFFFFF"
                                icon={qrCodeIcon}
                                iconSize={isSmallScreen ? 23 : 30}
                                errorLevel="Q"
                            />
                        </div>
                        <span className="paid_qrcode_desc">扫码添加专属客服</span>
                    </div>
                ) : null
            }

            <div className="paid_button_container">
                <Button
                    style={{color: "#333333"}}
                    className="paid_button_container_item"
                    onClick={() => location.href = "/app"}
                >
                    返回首页
                </Button>
                {
                    !isExternal ? (
                        <Button
                            className="paid_button_container_item"
                            type="primary"
                            onClick={() => {
                                if (appUrl) {
                                    window.open(appUrl, "_blank");
                                }
                            }}>
                            去使用
                        </Button>
                    ) : null
                }
            </div>
        </div>
    );
}

export default Paid;

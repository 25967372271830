const {useState} = React;

const HeaderMenuMobile = () => {
    const [showList, setShowList] = useState(false)

    function toggleHeaderMenuMobile() {
        const headerMenuMobile = document.getElementById('header_menu_mobile_static')
        const userProfile = document.getElementById('user_profile_container')
        console.log(headerMenuMobile, userProfile)
        if (headerMenuMobile) {
            const element = headerMenuMobile

            if (element.style.opacity === '0' || !element.style.opacity) {
                element.style.opacity = '1'
                element.style.zIndex = '100'
                element.style.height = '100vh'
                element.style.top = '44px'
                document.body.style.overflow = 'hidden'
                if (userProfile) {
                    userProfile.style.display = 'none'
                }
                setShowList(true)
            } else {
                element.style.opacity = '0'
                element.style.height = '0'
                element.style.zIndex = '100'
                element.style.top = '-44px'
                document.body.style.overflow = 'unset'
                if (userProfile) {
                    userProfile.style.display = 'flex'
                }
                setShowList(false)
            }
        }
    }

    return (
        <>
            <button type="button" style={{border: 'none', background: 'none'}} onClick={toggleHeaderMenuMobile}>
                <i className="iconfont" dangerouslySetInnerHTML={{__html: showList ? "&#xe807;" : "&#xe82e;"}}/>
            </button>
        </>
    )
}
export default HeaderMenuMobile
import {goLogin, toRecordInfo} from "../utils";
import {generateAppMarketInitialState} from "../api";
import {useDispatch} from "../hooks/useReduxStore";
import {ENTERPRISE_BASE_URL} from "../constants";

const {Input, Button} = antd
const {useState, useEffect, useRef} = React
const {useRequest} = ahooks;

function TopSearchInput(props) {
    const [input, setInput] = useState("")
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const {data: initialState, loading: initialStateLoading} = useRequest(generateAppMarketInitialState)
    const handlePressEnter = () => {
        window.location.href = window.location.origin + `/app/search?word=${input}`
    }
    useEffect(() => {
        const searchWord = localStorage.getItem("word")
        setInput(searchWord || "")
        if (!searchWord){
            inputRef.current.focus()
        }
    }, [])
    useEffect(() => {
        if (!initialStateLoading) {
            const submitRequestData = document.getElementById("app-hub-submit-request-data")
            const submitRequestDataEmpty = document.getElementById("app-hub-submit-request-data-empty")
            submitRequestData?.addEventListener("click", () => {
                if (!initialState.loginStatus){
                    goLogin(ENTERPRISE_BASE_URL)
                }else {
                    toRecordInfo(`${initialState?.enterprise?.name}的${initialState?.user_account?.username}，提交了定制应用的申请，请及时跟进`, initialState, dispatch)
                }
            })
            submitRequestDataEmpty?.addEventListener("click", () => {
                if (!initialState.loginStatus){
                    goLogin(ENTERPRISE_BASE_URL)
                }else {
                    toRecordInfo(`${initialState?.enterprise?.name}的${initialState?.user_account?.username}，提交了定制应用的申请，请及时跟进`, initialState, dispatch)
                }
            })
        }
    }, [initialState]);
    return (
        <Input
            ref={inputRef}
            style={{borderRadius: 20, height: 46, width: 512, paddingRight: 4}}
            styles={{input: {fontSize: 14, lineHeight: "22px"}}}
            placeholder="请输入应用名称"
            prefix={<img width={18} height={18} src="https://cdn-static.xiaoduoai.com/apphub/bitable/9900e1fd21842237d2858ff3f92503b9.png"
                         alt="search-icon"/>}
            value={input}
            onChange={(e) => setInput(e.target.value)} onPressEnter={handlePressEnter}
            suffix={<Button type="primary" style={{
                borderRadius: 18, width: 76, height: 38,
                // background: "linear-gradient( 180deg, #5E90FF 0%, rgba(47,155,255,0.86) 100%)"
            }} onClick={handlePressEnter}>搜索</Button>}
        />
    );
}

export default TopSearchInput;
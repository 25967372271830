const ul = document.getElementById("detail_app_hub_product_main_introduction_tab")

if (ul){
    const firstLi = ul.querySelector("li"); // 获取第一个 li
    ul.addEventListener("click", (e) => {
        console.log("eee",e)
        window.e = e
        if (e.target.nodeName === "LI") { // 确保点击的是 li
            const lis = ul.querySelectorAll("li");
            console.log("lis",lis)
            lis.forEach(li => li.classList.remove("detail_app_hub_product_main_introduction_tab_item_active"));

            // 给当前点击的 li 添加 active 类
            e.target.classList.add("detail_app_hub_product_main_introduction_tab_item_active");
            const title = e.target.dataset.title
            console.log(title,"title")
            if (title){
                const targetElement = document.getElementById(title)
                if (targetElement) {
                    // 计算滚动位置，减去你希望的偏移量
                    const offset = 80; // 这里设置你想减少的滚动距离
                    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;
                    window.scrollTo({
                        top: targetPosition,
                        behavior: 'smooth'
                    });
                }
            }
        }
    });

    window.addEventListener("scroll", () => {
        if (window.scrollY < 330) { // 你可以调整这个 100 作为滚动触发点
            const lis = ul.querySelectorAll("li");
            lis.forEach(li => li.classList.remove("detail_app_hub_product_main_introduction_tab_item_active"));
            if (firstLi) {
                firstLi.classList.add("detail_app_hub_product_main_introduction_tab_item_active");
            }
        }
    });
}


const {configureStore, createSlice} = window.RTK;


const initialState = {
    appTable: {
        refresh: false,
    },
    payModal: {
        visible: false,
        orderType: 'purchase',
        productUrl: '',
        isLoading: false,
        categories: [],
        appList: [],
        appId: '',
        from: '', // 如果从APPTable过来则不刷新
        currentProduct: null
    },
    recordModal: {
        initialState: {} ,
        isShow: false,
        showExtra: {
            source: '',
            editType: '',
        },
    },
    initialState: {} ,
    imageViewer: {
        items: [],
        visible: false,
        visiblePc: false,
        currentItem: '',
    },
}
const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        triggerAppTableRefresh: (state) => {
            state.appTable = {
                ...state.appTable,
                refresh: !state.appTable.refresh,
            }
        },
        // 报价器弹窗
        // setPayModalFrom(state, action) {
        //     state.payModal = {
        //         ...state.payModal,
        //         from: action.payload,
        //     }
        // },
        setPayModalCurrentProduct(state, action) {
            state.payModal = {
                ...state.payModal,
                currentProduct: action.payload,
            }
        },
        setPayModalVisible(state, action) {
            state.payModal = {
                ...state.payModal, // 保留其他属性
                visible: action.payload, // 更新目标属性
            }
        },
        setPayModalLoading(state, action) {
            state.payModal = {
                ...state.payModal,
                isLoading: action.payload,
            }
        },
        setPayModalOrderType(state, action) {
            state.payModal = {
                ...state.payModal,
                orderType: action.payload,
            }
        },
        setPayModalProductUrl(state, action) {
            state.payModal = {
                ...state.payModal,
                productUrl: action.payload,
            }
        },
        // setPayModalCategories(state, action) {
        //     state.payModal = {
        //         ...state.payModal,
        //         categories: action.payload,
        //     }
        // },
        setPayModalAppList(state, action) {
            state.payModal = {
                ...state.payModal,
                appList: action.payload,
            }
        },
        setPayModalAppId(state, action) {
            state.payModal = {
                ...state.payModal,
                appId: action.payload,
            }
        },
        resetPayModal(state) {
            state.payModal = {
                from: '',
                visible: false,
                orderType: 'purchase',
                productUrl: '',
                isLoading: false,
                categories: [],
                appList: [],
                appId: '',
                currentProduct:state.payModal.currentProduct
            }
        },
        // 留资弹窗
        setRecordModalInitialState(state, action) {
            state.recordModal = {
                ...state.recordModal, // 保留其他属性
                initialState: action.payload, // 更新目标属性
            }
        },
        setRecordModalShow(state, action) {
            state.recordModal = {
                ...state.recordModal,
                isShow: action.payload,
            }
        },
        setRecordModalShowExtra(state, action) {
            state.recordModal = {
                ...state.recordModal,
                showExtra: action.payload,
            }
        },
        resetRecordModal(state) {
            state.recordModal = {
                initialState: {} ,
                isShow: false,
                showExtra: {
                    source: '',
                    editType: '',
                },
            }
        },
        setImageViewerList(state, action) {
            state.imageViewer = {
                ...state.imageViewer,
                items: action.payload,
            }
        },
        setImageViewerListVisible(state, action) {
            state.imageViewer = {
                ...state.imageViewer,
                visible: action.payload,
            }
        },
        setImageViewerListPcVisible(state, action) {
            state.imageViewer = {
                ...state.imageViewer,
                visiblePc: action.payload,
            }
        },
        setImageViewerListCurrentItem(state, action) {
            console.log(action.payload)
            state.imageViewer = {
                ...state.imageViewer,
                currentItem: action.payload,
            }
        },
    },
})

export const {
    setPayModalVisible,
    setPayModalLoading,
    setPayModalOrderType,
    setPayModalProductUrl,
    // setPayModalCategories,
    setPayModalAppList,
    setPayModalAppId,
    resetPayModal,
    setRecordModalInitialState,
    setRecordModalShow,
    setRecordModalShowExtra,
    resetRecordModal,
    // setPayModalFrom,
    triggerAppTableRefresh,
    setImageViewerList,
    setImageViewerListVisible,
    setImageViewerListCurrentItem,
    setImageViewerListPcVisible,
    setPayModalCurrentProduct
} = globalSlice.actions

const store = configureStore({
    reducer: {
        global: globalSlice.reducer,
    },
})

export const selectPayModal = (state) => state.global.payModal
export const selectRecordModal = (state) => state.global.recordModal
export const selectAppTable = (state) => state.global.appTable
export const selectImageViewer = (state) => state.global.imageViewer
export const selectImageViewerCurrentIndex = (state) => {
    const {imageViewer} = state.global
    const index = imageViewer?.items?.findIndex((item) => item === imageViewer.currentItem)
    return index === -1 ? 0 : index
}
export default store

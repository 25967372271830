import { getAppList } from '../api'
import { useSelect } from '../hooks/useSelect'
import {
    resetPayModal,
    selectPayModal,
    setPayModalAppList,
    setPayModalLoading,
} from '../store'
import { useDispatch } from '../hooks/useReduxStore'


const { PayModal } = xdPayment.default
const PayModalCom = () => {
    const payModalData = useSelect(selectPayModal)
    const currentProduct = payModalData?.currentProduct
    const dispatch = useDispatch()
    if (!currentProduct?.app_instance) return null
    let redirectUrl
    if (payModalData?.appId){
        redirectUrl = `${window.location.origin}/app/paid?paid_app_id=${payModalData?.appId}&paid_source_type=1`
    }else {
        redirectUrl = `${window.location.origin}/app/paid?paid_app_id=${currentProduct?.app_instance?.id}&paid_source_type=0`
    }
    return (
        <>
            <PayModal
                visible={payModalData?.visible}
                close={() => dispatch(resetPayModal())}
                app_id={payModalData?.appId || currentProduct?.app_instance?.id}
                type={payModalData?.orderType}
                url={redirectUrl}
                // proxy
                okPay={async ({isFree, isPaid, app_id, source_type}) => {
                    dispatch(setPayModalLoading(true))
                    const {products:appList} = await getAppList()
                    dispatch(setPayModalAppList(appList))
                    dispatch(setPayModalLoading(false))
                    if (isFree){
                        window.location.href = `/app/paid?paid_app_id=${app_id}&paid_source_type=${source_type}`
                    }
                    if (isPaid){
                        // todo 用户点击确认已经支付完成的情况
                    }
                }}
            />
        </>
    )
}

export default PayModalCom

const {Modal} = antd
/**
 *  非超管无权限操作应用订购、续费等提示弹窗
 * @param name 超管备注名
 */
export const showAdminPermissionModal = (name) => {
    Modal.info({
        width: 460,
        centered: true,
        title: (
            <div style={{fontSize: '16px', color: '#333333', fontWeight: 600,paddingLeft:"4px"}} data-testid="admin-modal-title">
                很抱歉，您暂无此操作权限
            </div>
        ),
        content: (
            <div style={{marginTop: '12px'}}>
                应用的开通、续订、增购或升级等操作受权限控制，请联系管理员
                <span>“{name}”或有权限的用户</span>
            </div>
        ),
        closable: true, // 更好的用户体验
        icon: (
            <div className="iconfont" style={{fontSize: '20px', lineHeight: "24px"}}>&#xe841;</div>
        ),
        keyboard: true, // 默认行为
        okText: '我知道了',
    });
}
// 检查浏览器是否支持 localStorage
if (typeof (Storage) !== "undefined") {
    // 获取当前 URL 的查询字符串（包括 '?'）
    const queryString = window.location.search;

    if (queryString) {
        // 创建一个 URLSearchParams 对象来解析查询字符串
        const urlParams = new URLSearchParams(queryString);

        // 遍历所有的参数并存储到 localStorage
        urlParams.forEach((value, key) => {
            try {
                localStorage.setItem(key, value);
                console.log(`已存储 ${key}: ${value} 到 localStorage`);
            } catch (e) {
                console.error(`无法存储 ${key} 到 localStorage:`, e);
            }
        });

        // 构建新的 URL，不包含查询字符串
        const newUrl = window.location.origin + window.location.pathname + window.location.hash;

        // 使用 history.replaceState 来更新浏览器的地址栏，而不重新加载页面
        window.history.replaceState({}, document.title, newUrl);

        console.log("已移除 URL 中的查询字符串");
    } else {
        console.log("当前 URL 没有查询字符串，无需处理。");
    }
} else {
    console.error("您的浏览器不支持 localStorage。");
}
import {
    addFingerId,
    addLeads,
    getReserveInfo,
    reserve,
    updateReserveInfo,
} from '../api'

import { useSelect } from '../hooks/useSelect'
import { resetRecordModal, selectRecordModal, setRecordModalShow } from '../store'
import { useDispatch } from '../hooks/useReduxStore'
const { useEffect, useState } = React
const { Button, Checkbox, Form, Input, message, Modal, Select } = antd
const { Item } = Form

const RecordModal = () => {
    const recordModal = useSelect(selectRecordModal)
    const dispatch = useDispatch()
    const { isShow, showExtra, initialState } = recordModal
    const extra = initialState?.extra
    const [hasAlreadyProvidedInfo, setHasAlreadyProvidedInfo] = useState(false)

    // 致趣百川上报
    const escalationInfo = (params) => {
        try {
            window.myCollect.$customClick({
                action: '晓总管上报手机号',
                setUserFlag: {
                    PhoneNumber: initialState?.encrypted_phone,
                },
            })
            const datfingerId = localStorage.getItem('fingerprintId')
            if (datfingerId) {
                addFingerId({ values: { fingerId: datfingerId } })
            }
            addLeads({
                values: {
                    xd_user_name: initialState?.user_account?.username, // 账号名
                    xd_team: initialState?.enterprise?.name || initialState?.enterprise?.shot_name, //团队名称
                    email: initialState?.user_account?.email, //邮箱
                    sourcepage: showExtra?.source || extra?.source, //来源页面
                    buttonclick: showExtra?.editType || extra?.editType, //操作按钮
                    ...(params || {}),
                },
            })
        } catch (error) {}
    }

    useEffect(() => {
        try {
            if (isShow && (extra?.source || showExtra?.source)) {
                getReserveInfo().then(({ code, data }) => {
                    if (
                        localStorage.getItem('leadUserId') === initialState?.user_account?.id &&
                        localStorage.getItem('leadPosition')
                    ) {
                        setHasAlreadyProvidedInfo(true)
                        reserve({
                            values: {
                                source: showExtra?.source || extra?.source,
                                extra: showExtra?.source ? showExtra : extra,
                            },
                        })
                    } else {
                        escalationInfo()
                        reserve({
                            values: {
                                source: showExtra?.source || extra?.source,
                                extra: showExtra?.source ? showExtra : extra,
                            },
                        })
                    }
                })
            }
        } catch {}
    }, [extra, showExtra, isShow])

    const [form] = Form.useForm()
    const CheckboxGroup = Checkbox.Group

    const productsOptions = [
        { title: '智能客服机器人', detail: '淘宝、京东、抖音、拼多多、快手', value: 'a' },
        { title: '客户运营', detail: '客户运营精细化运营', value: 'b' },
        { title: '协同系统', detail: '构建标准化、数智化售后问题解决流程', value: 'c' },
        { title: '智能客服系统', detail: '全渠道统一数智化客服服务解决方案', value: 'd' },
        { title: '质培系统', detail: '管理提升消费者全链路体验', value: 'e' },
        { title: '客服代运营', detail: '专家级服务解决方案代运营', value: 'f' },
        { title: '多策', detail: '智能让知识流动，多策让客户成交', value: 'g' },
        { title: '训练场', detail: '让客服快速成为销冠', value: 'h' },
        { title: '聚合接待', detail: '多平台，多账号买家聚合接待', value: 'i' },
        { title: '整体解决方案', detail: '', value: 'j' },
        { title: '其他', detail: '', value: 'k' },
    ]

    const renderProductsOptions = () => {
        return productsOptions.map(({ title, detail, value }) => ({
            label: (
                <div
                    key={value}
                    className="products_option"
                    data-testid="record-lead-products-option"
                >
          <span className="products_title" data-testid="record-lead-products-title">
            {title}
          </span>
                    {detail && (
                        <span className="products_detail" data-testid="record-lead-products-detail">
              {detail}
            </span>
                    )}
                </div>
            ),
            value,
        }))
    }

    //职位列表
    const positionList = [
        {
            label: '公司决策层',
            value: '1',
        },
        {
            label: '业务主管/经理',
            value: '2',
        },
        {
            label: '一线业务人员',
            value: '3',
        },
        {
            label: '其他',
            value: '4',
        },
    ]

    const handleCancel = () => {
        dispatch(setRecordModalShow(false))
        dispatch(resetRecordModal())
    }
    const handleUpdate = async () => {
        form.validateFields().then(({ company, customer_email, real_name, position, products }) => {
            //上传留资个人信息
            updateReserveInfo({
                values: {
                    company,
                    real_name,
                    position,
                    customer_email,
                    products: products.join(','),
                },
            })
                .then(({ code }) => {
                    if (code === 0) {
                        message.success('提交成功')
                        localStorage.setItem('leadUserId', initialState?.user_account?.id)
                        localStorage.setItem('leadPosition', position)
                    } else {
                        message.error('提交失败')
                    }
                })
                .catch()
                .finally(() => handleCancel())
            escalationInfo({
                company, //公司
                full_name: real_name, //姓名
                job_title: positionList
                    .filter((k) => k.value === position)
                    .map((i) => ({ ...i, value: Number(i.value) })), //职位
                products_of_interest: products.join(','), //感觉兴趣的产品
            })
        })
        dispatch(setRecordModalShow(false))
        dispatch(resetRecordModal())
    }
    return (
        <Modal
            className="record_container"
            data-testid="record-lead-container"
            width={hasAlreadyProvidedInfo ? 498 : 520}
            maskClosable={false}
            destroyOnClose
            open={isShow}
            onCancel={handleCancel}
            onOk={handleUpdate}
            cancelText="暂不补充"
            okText="提交信息"
            title={<div style={{ height: 16 }} />}
            footer={hasAlreadyProvidedInfo ? null : undefined}
        >
            <div className="modal" data-testid="record-lead-modal">
                <div
                    className="body"
                    data-testid="record-lead-modal-body"
                    style={{ marginTop: hasAlreadyProvidedInfo ? 24 : 0 }}
                >
                    <i className="iconfont" style={{ fontSize: hasAlreadyProvidedInfo ? 64 : 48 }} data-testid="record-lead-record-icon">&#xe823;</i>
                    <h2 className="record_received" data-testid="record-lead-modal-received">
                        已收到
                    </h2>
                    <div className="operates" data-testid="record-lead-modal-operates">
            <span>
              您的专属顾问将在 24 小时内与您取得联系，以下是我们的专用号码，您还可以添加顾问微信进行
              1V1 沟通~
            </span>
                    </div>
                    <div
                        className="connect"
                        data-testid="record-lead-modal-connect"
                        style={{ marginTop: hasAlreadyProvidedInfo ? 50 : 16 }}
                    >
                        <div className="phone_wrap" data-testid="record-lead-modal-phone-wrap">
                            <i className="iconfont tel_icon">&#xe6b1;</i>
                            <div className="phone" data-testid="record-lead-modal-phone">
                                13689087523
                            </div>
                        </div>
                        <img
                            src="https://cdn-static.xiaoduoai.com/apphub/bitable/462263e4b00771faa7fee70305be2910.png"
                            width={64}
                            height={64}
                            alt=""
                        />
                    </div>
                    {!hasAlreadyProvidedInfo && (
                        <>
                            <div className="info_tips" data-testid="record-lead-modal-info-tips">
                                <i className="iconfont tips_icon" data-testid="record-lead-modal-info-tips-icon">&#xe841;</i>
                                <span>完善信息，更好地为您提供个性化的解决方案</span>
                            </div>
                            <Form
                                preserve={false}
                                form={form}
                                wrapperCol={{ span: 24 }}
                                className="customer_info_form"
                                data-testid="record-lead-modal-customer-info-form"
                            >
                                <Item
                                    name="company"
                                    style={{ marginTop: 15, marginBottom: 16 }}
                                    rules={[{ required: true, message: '请输入您的公司名称' }]}
                                >
                                    <Input placeholder="请输入您的公司名称" allowClear />
                                </Item>
                                <Item
                                    name="real_name"
                                    style={{ marginBottom: 16 }}
                                    rules={[{ required: true, message: '请输入您的姓名' }]}
                                >
                                    <Input placeholder="请输入您的姓名" allowClear />
                                </Item>
                                <Item
                                    name="position"
                                    style={{ marginBottom: 16 }}
                                    rules={[{ required: true, message: '请选择您的职位' }]}
                                >
                                    <Select placeholder="请选择您的职位" allowClear options={positionList} />
                                </Item>
                                <div
                                    className="customer_info_form_list"
                                    data-testid="record-lead-modal-customer-info-form-list"
                                >
                                    您感兴趣的产品:
                                </div>
                                <Item
                                    name="products"
                                    rules={[{ required: true, message: '请选择您感兴趣的产品' }]}
                                    className="checkbox_group"
                                    data-testid="record-lead-modal-checkbox-group"
                                >
                                    <CheckboxGroup
                                        style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                                        options={renderProductsOptions()}
                                    />
                                </Item>
                            </Form>
                        </>
                    )}
                    {hasAlreadyProvidedInfo && (
                        <Button type="primary" onClick={handleCancel} style={{ marginTop: 32 }}>
                            我知道了
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default RecordModal

import {getCurrentKey, goLogin, handlePay, toOrder, toTrial, toRecordInfo} from '../utils'
import {generateAppMarketInitialState, getAppList, getEnterpriseLoginUrl} from '../api'
import {useDispatch} from '../hooks/useReduxStore'
import {
    selectPayModal,
    setPayModalProductUrl,
    setPayModalCurrentProduct,
    setPayModalAppId,
    setRecordModalInitialState
} from '../store'
import {useSelect} from '../hooks/useSelect'
import {ENTERPRISE_BASE_URL} from "../constants";

const {useRequest} = ahooks;
const {useEffect, useId, useState} = React
const {Button} = antd

// billed_type: 0 | 1 | 2 // 0免费  1.按时长  2.按流量
// const statusTexts = {
//     0: '未订购',
//     1: '生效中',
//     2: '已到期',
//     3: '无权限', // 无权限
//     4: '未绑定', // 未绑定
// };
function DetailButtons({shouldBind = true}) {

    const {data: initialState, loading: initialStateLoading} = useRequest(generateAppMarketInitialState)
    const {data: allProducts, error, loading, refresh} = useRequest(getAppList)
    const [customButtons, setCustomButtons] = useState([])
    const dispatch = useDispatch()
    const payModalData = useSelect(selectPayModal)
    const {product_key, app_key} = getCurrentKey(window.location.pathname)
    const currentProduct = allProducts?.products?.filter(product => product?.product?.product_key === product_key)?.[0]
    const currentApp = allProducts?.apps?.filter(plugin => plugin.plugin_key === app_key)?.[0]
    const productStatus = currentProduct?.product?.status
    const notLogin = !initialStateLoading && !initialState?.loginStatus
    const button_key = app_key ? app_key : product_key // 当前处理的是产品还是应用的button

    // 产品或应用当前状态
    const productNotOrder = productStatus === 0 || productStatus === 2 // 产品失效
    const productNotOrderCanTrial = productStatus === 0 && currentProduct?.app_instance?.support_trial_order // 产品未订购支持试用
    const productNotOrderButCanBuy = productNotOrder && currentProduct?.app_instance?.support_online_order // 产品失效但是支持购买
    const isProductSubscriptionRenewable = productStatus === 1 && currentProduct?.app_instance?.support_online_order // 产品在期,支持续订
    const isProductAdditionalPurchaseSupported = isProductSubscriptionRenewable && currentProduct?.app_instance?.billed_type === 2 // 产品在期,且支持按流量购买,支持增购流量
    const appNotOrder = currentApp && currentApp?.order_status === 0 || currentApp?.order_status === 2 // 应用失效
    const isAppAndOrdered = app_key && !appNotOrder && !notLogin // 是app且订购且是处于登录状态
    const appNotOrderButCanTrial = currentApp && currentApp?.order_status === 0 && currentApp?.support_trial_order // 应用未订购但是支持试用
    const appNotOrderButCanBuy = appNotOrder && currentApp?.support_online_order && !productNotOrder// 应用失效但是支持购买
    const appIsActiveAndSupportsOnlineOrdering = currentApp && currentApp?.order_status === 1 && currentApp?.support_online_order // 应用在期,且支持续订
    const appExpiryTimeBeforeProductExpiryTime = moment.unix(currentApp?.expired_at).isBefore(moment.unix(currentProduct?.product?.expire_ts)) // 应用先于产品过期
    const isAppSubscriptionRenewable = appIsActiveAndSupportsOnlineOrdering && appExpiryTimeBeforeProductExpiryTime // 应用先于产品过期,可以续订至产品过期时间
    const isAppAdditionalPurchaseSupported = appIsActiveAndSupportsOnlineOrdering && currentProduct?.product?.expire_ts && currentApp?.billed_type === 2 // 应用支持按流量增购

    const hasOrder = (currentApp ? !appNotOrder : !productNotOrder) && !notLogin // 产品已订购或应用已订购 且处于登录状态

    // 不在本页面处理订购的产品
    const integralProducts = ['xdtech_service_platform']
    const isIntegralProduct = integralProducts.includes(product_key)
    const integralProductCanTrial = integralProducts.includes(product_key) && productStatus === 0
    const integralProductCanRenewable = integralProducts.includes(product_key) && productStatus === 1
    const integralProductCanBuy = integralProducts.includes(product_key) && productStatus === 2
    // 处理button显示情况
    const showTrialButton = ((notLogin || integralProductCanTrial || productNotOrderCanTrial) && !app_key) || appNotOrderButCanTrial
    const showBuyButton = ((notLogin || integralProductCanBuy || productNotOrderButCanBuy) && !app_key) || appNotOrderButCanBuy
    const showRenewableButton = ((integralProductCanRenewable || isProductSubscriptionRenewable) && !app_key) || isAppSubscriptionRenewable
    const showAdditionalPurchaseButton = (isProductAdditionalPurchaseSupported && !app_key) || isAppAdditionalPurchaseSupported

    useEffect(() => {
        const custom_buttons = document.getElementsByClassName("detail_app_hub_product_custom_button")
        if (custom_buttons.length) {
            const custom_buttons_data = []
            for (let i = 0; i < custom_buttons.length; i++) {
                const custom_button = custom_buttons[i]
                const button_type = custom_button?.dataset?.type
                const button_url = custom_button?.dataset?.url
                const button_name = custom_button?.dataset.name

                custom_buttons_data.push({
                    button_type,
                    button_url,
                    button_name
                })
            }
            setCustomButtons(custom_buttons_data)
        }
        let app_hub_detail_product_order_tip
        let app_hub_detail_product_order_tip_contact
        let app_hub_detail_product_order_tip_buy
        if (shouldBind) {
            // 处理应用下面的tip
            app_hub_detail_product_order_tip = document.getElementById("app-hub-detail-product-order-tip")
            app_hub_detail_product_order_tip_contact = document.getElementById("app-hub-detail-product-order-tip-contact")
            app_hub_detail_product_order_tip_buy = document.getElementById("app-hub-detail-product-order-tip-buy")
        } else {
            app_hub_detail_product_order_tip = document.getElementById("app-hub-detail-product-order-tip-mobile")
            app_hub_detail_product_order_tip_contact = document.getElementById("app-hub-detail-product-order-tip-contact-mobile")
            app_hub_detail_product_order_tip_buy = document.getElementById("app-hub-detail-product-order-tip-buy-mobile")
        }
        if (!initialStateLoading && !loading && app_hub_detail_product_order_tip && productNotOrder) {
            if (app_key) {
                app_hub_detail_product_order_tip.style.display = "flex"
                if (currentProduct?.app_instance?.support_online_order) {
                    app_hub_detail_product_order_tip_buy.style.display = "inline"
                } else {
                    app_hub_detail_product_order_tip_contact.style.display = "inline"
                }
            }
            app_hub_detail_product_order_tip_buy.addEventListener("click", () => {
                if (isIntegralProduct) {
                    toOrder(product_key, initialState, dispatch, 'order')
                } else {
                    handlePay('purchase', initialState, dispatch, "")
                }
            })
            app_hub_detail_product_order_tip_contact.addEventListener("click", () => {
                toRecordInfo(`${currentProduct?.product?.name}_联系专属销售`, initialState, dispatch)
            })
        }
    }, [currentProduct, currentApp, initialState])
    useEffect(() => {
        if (currentProduct) {
            dispatch(setPayModalCurrentProduct(currentProduct))
        }
        if (currentApp) {
            dispatch(setPayModalAppId(currentApp.id))
        }
    }, [loading])
    useEffect(() => {
        const product_key = currentProduct?.product?.product_key
        if (product_key && currentProduct?.app_instance?.support_online_order) {
            getEnterpriseLoginUrl({
                values: {
                    product_key,
                },
            }).then((result) => {
                dispatch(setPayModalProductUrl(result?.data?.url))
            })
        }
    }, [currentProduct])
    useEffect(() => {
        if (initialState) {
            dispatch(setRecordModalInitialState(initialState))
        }
    }, [initialState]);

    useEffect(() => {
        if (!initialStateLoading) {
            const callback = localStorage.getItem('callback')
            localStorage.removeItem("callback")
            if (!callback) return
            const callbacks = {
                toOrder: {
                    func: toOrder,
                    params: [[button_key], [initialState], [dispatch], 'type', 'self'],
                },
                handlePay: {
                    func: handlePay,
                    params: ['type', [initialState], [dispatch], currentApp?.id],
                },
                toRecordInfo: {
                    func: toRecordInfo,
                    params: ['type', [initialState], [dispatch], 'source'],
                },
            }
            const currentCallbackParamKeys = callbacks[callback].params
            const currentCallbackParams = currentCallbackParamKeys.map(
                (currentCallbackParamKey) => {
                    if (Array.isArray(currentCallbackParamKey)) return currentCallbackParamKey[0]
                    const currentCallbackParamValue = localStorage.getItem(currentCallbackParamKey)
                    localStorage.removeItem(currentCallbackParamKey)
                    return currentCallbackParamValue
                }
            )
            callbacks[callback].func(...currentCallbackParams)
        }
    }, [initialStateLoading])
    const displayedCustomButtons = customButtons.filter(customButton => {
        if (
            ((showTrialButton || showRenewableButton || showAdditionalPurchaseButton) || hasOrder) &&
            customButton.button_name === "免费试用"
        ) {
            return false;
        }

        if (
            ((showBuyButton || showRenewableButton || showAdditionalPurchaseButton) || hasOrder) &&
            customButton.button_name === "立即订购"
        ) {
            return false;
        }
        return true;
    })
    if (initialStateLoading || loading) return null
    return (
        <>
            {displayedCustomButtons.map((customButton, index) => {
                return (
                    <Button
                        type={index === 0 ? "primary" : ""}
                        onClick={() => {
                            if (!initialStateLoading && !initialState?.loginStatus) {
                                return goLogin(ENTERPRISE_BASE_URL)
                            }
                            if (customButton.button_type === "0") {
                                let name = currentProduct?.product?.name
                                if (app_key) {
                                    name = currentApp.name
                                }
                                console.log("record", `${name}_${customButton.button_name}`)
                                toRecordInfo(`${name}_${customButton.button_name}`, initialState, dispatch)
                            } else if (customButton.button_type === "1" && customButton.button_url) {
                                window.open(customButton.button_url, "_blank")
                            }
                        }}>
                        {customButton.button_name}
                    </Button>
                )
            })}
            {
                showTrialButton ? (
                    <Button
                        className={`xd_${button_key}_trial_btn`}
                        key={`xd_${button_key}_trial_btn`}
                        data-testid="detail-buttons-trial-btn"
                        type={!displayedCustomButtons.length ? "primary" : ""}
                        onClick={() => {
                            if (notLogin) {
                                goLogin(ENTERPRISE_BASE_URL)
                            } else {
                                toTrial(button_key, initialState, dispatch, 'trial')
                            }
                        }
                        }
                    >
                        免费试用
                    </Button>
                ) : null
            }
            {
                showBuyButton ? (
                    <Button
                        type={!displayedCustomButtons.length && !showTrialButton ? "primary" : ""}
                        className={`xd_${button_key}_order_btn`}
                        key={`xd_${button_key}_order_btn`}
                        data-testid="detail-buttons-order-btn"
                        loading={payModalData.isLoading}
                        onClick={() => {
                            if (notLogin) {
                                goLogin(ENTERPRISE_BASE_URL)
                            } else {
                                if (isIntegralProduct) {
                                    toOrder(button_key, initialState, dispatch, 'order')
                                } else {
                                    handlePay('purchase', initialState, dispatch, currentApp?.id)
                                }
                            }
                        }}
                    >
                        立即订购
                    </Button>
                ) : null
            }
            {
                showRenewableButton ? (
                    <Button
                        type={!displayedCustomButtons.length && !showTrialButton && !showBuyButton ? "primary" : ""}
                        className={`xd_${button_key}_renew_btn`}
                        key={`xd_${button_key}_renew_btn`}
                        data-testid="detail-buttons-renew-btn"
                        loading={payModalData.isLoading}
                        onClick={() => {
                            if (isIntegralProduct) {
                                toOrder(button_key, initialState, dispatch, 'order')
                            } else {
                                handlePay('renew', initialState, dispatch, currentApp?.id)
                            }
                        }}
                    >
                        续订
                    </Button>
                ) : null
            }
            {
                showAdditionalPurchaseButton ?
                    (
                        <Button
                            type={!displayedCustomButtons.length && !showTrialButton && !showBuyButton && !showRenewableButton ? "primary" : ""}
                            className={`xd_${button_key}_repurchase_btn`}
                            key={`xd_${button_key}_repurchase_btn`}
                            data-testid="detail-buttons-repurchase-btn"
                            loading={payModalData.isLoading}
                            onClick={() => handlePay('repurchase', initialState, dispatch, currentApp?.id)}
                        >
                            增购
                        </Button>
                    ) : null
            }
        </>
    )

}

export default DetailButtons

import {sensorsInit} from "../utils/sensor";
import {ENTERPRISE_BASE_URL, CONSOLE_BASE_URL} from "../constants";

const sensorsLocal = sensorsInit()
const qs = Qs

axios.defaults.withCredentials = true; // 使所有请求携带 cookies

export const getAppInstance = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/app/item`, values, config)
    return response?.data?.data
}

export const getPlugin = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/plugin/item`, values, config)
    return response?.data?.data
}

export const getUserInfo = async ({config} = {}) => {
    const response = await axios.get(`${ENTERPRISE_BASE_URL}/api/user_account/info`, config)
    return response?.data?.data
}

export const getEnterpriseList = async ({
                                            config,
                                        } = {}) => {
    const response = await axios.get(`${ENTERPRISE_BASE_URL}/api/user_account/enterprise/list`, config)
    return response?.data?.data
}

export const getLoginStatus = async ({config} = {}) => {
    const response = await axios.get(`${ENTERPRISE_BASE_URL}/__enterprise__/account/logined`, config)
    return response?.data
}

export const logout = async ({config} = {}) => {
    return axios.get(`${ENTERPRISE_BASE_URL}/__enterprise__/account/logout`, config)
}
export const getTenantPermission = async ({config, values} = {}) => {
    const response = await axios.post(
        `${ENTERPRISE_BASE_URL}/api/permission/tenant_permission`,
        {
            product_key: values?.appId,
            token: values?.token,
        },
        config
    )
    const {code, data} = response?.data || {}
    return code === 0
        ? {code, data: data?.permission || {}}
        : {code, data: {}, message: '获取权限发生错误'}
}

export const getEnterpriseLoginUrl = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/login_plat/enterprise_login_url`, values, config)
    return response?.data
}
export const can_trial = async ({config, values} = {}) => {
    const response = await axios.get(`${ENTERPRISE_BASE_URL}/api/product/can_trial?${qs.stringify(values)}`, config)
    return response?.data?.data
}

export const getAppList = async ({config} = {}) => {
    let response = await axios.get(`${ENTERPRISE_BASE_URL}/api/app/list`, config)
    const items = response?.data?.data?.items
    const data = {
        products: items,
        apps: null
    }
    data.apps = items?.reduce((prev, next) => {
        return prev.concat(next?.app_instance?.plugins || [])
    }, [])
    return data
}

export const trial = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/product/trial`, values, config)
    return response?.data?.data
}

export const generateAppMarketInitialState = async () => {
    try {
        const {
            user_account,
            staff,
            enterprise,
            roles,
            admin_phone = '',
            admin_name,
            perfect = false,
            super_admin_register_url,
            router_visible = [],
            encrypted_phone = '',
        } = await getUserInfo()
        const {items: enterpriseList} = await getEnterpriseList()
        const {en_token} = await getLoginStatus()
        const permission = await getTenantPermission({
            values: {
                appId: 'enterprise',
                token: en_token,
            },
        })
        let featureList = []

        if (permission.code === 0) {
            featureList = permission?.data?.features || []
        } else {
            console.error(permission)
        }
        sensorsLocal.registerPage({
            enterprise_id: enterprise.id,
            user_account_id: user_account.id,
            staff_name: staff.name,
        })
        sensorsLocal.login(`${enterprise.name}::${user_account.username}`)
        sensorsLocal.quick('autoTrack')

        return {
            user_account,
            staff,
            enterprise,
            enterpriseList,
            roles,
            admin_phone,
            admin_name,
            perfect,
            super_admin_register_url,
            router_visible,
            encrypted_phone,
            featureList,
            loginStatus: true,
        }
    } catch (e) {
        console.log('调试信息,处于未登录状态：', e)
        return {
            loginStatus: false,
        }
    }
}

export const addFingerId = async ({config, values} = {}) => {
    return axios.post("https://nrkoi5.faas.xiaoduoai.com/llm/log", values, {
        withCredentials: false,
        ...config,
    })
}

//致趣百川转化为线索
export const addLeads = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/user_account/add_leads`, values, config)
    return response?.data
}

export const getReserveInfo = async ({config} = {}) => {
    const response = await axios.get(`${ENTERPRISE_BASE_URL}/api/product/reserve_info`, config)
    return response?.data
}

export const reserve = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/product/reserve`, values, config)
    return response?.data
}

export const updateReserveInfo = async ({config, values} = {}) => {
    const response = await axios.post(`${ENTERPRISE_BASE_URL}/api/product/reserve_update`, values, config)
    return response?.data
}

export const changeEnterprise = async ({config, values} = {}) => {
    const response = await axios.post(
        `${ENTERPRISE_BASE_URL}/api/user_account/enterprise_login`,
        {
            ...values,
        },
        config
    )
    return response?.data
}

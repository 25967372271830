const APPHUB_ENV = process.env.APPHUB_ENV

let CONSOLE_BASE_URL
let ENTERPRISE_BASE_URL

if (APPHUB_ENV === "production") {
    CONSOLE_BASE_URL = "https://console.xiaoduoai.com"
    ENTERPRISE_BASE_URL = "https://enterprise.xiaoduoai.com"
} else {
    CONSOLE_BASE_URL = "https://console-dev-lane.xiaoduoai.com"
    ENTERPRISE_BASE_URL = "https://enterprise-dev-lane.xiaoduoai.com"
}

export {
    CONSOLE_BASE_URL,
    ENTERPRISE_BASE_URL
}
import {generateAppMarketInitialState, logout,changeEnterprise} from "../api";
import {ENTERPRISE_BASE_URL} from "../constants";
const {useState, useEffect} = React;
const {Dropdown, Menu, message} = antd;
const {useRequest} = ahooks;
const {CheckOutlined} = icons
const SubMenuRender = ({
                           groupName,
                           manager,
                           selected,
                       }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            <div>
                <div>{groupName}</div>
                <div style={{fontSize: 12, color: 'gray'}}>{manager}</div>
            </div>
            <div style={{marginLeft: 10, visibility: selected ? 'visible' : 'hidden'}}>
                <CheckOutlined style={{color: '#1890FF'}}/>
            </div>
        </div>
    )
}


function UserProfile() {
    const [userDropDownItems, setUserDropDownItems] = useState([])
    const {data: initialState, error, loading, refresh} = useRequest(generateAppMarketInitialState)
    const [pathname, setPathname] = useState('')
    const isLogin = !!initialState?.user_account
    const accessOrderProductsFromAppMarket = initialState?.featureList?.includes(
        'enterprise.account.orders.orderProducts'
    )
    console.log(initialState)
    useEffect(() => {
        setPathname(window?.location?.href)
    }, [])
    useEffect(() => {
        if (isLogin) {
            setUserDropDownItems([
                {
                    key: 'current',
                    label: <div>当前团队</div>,
                    disabled: true,
                },
                {
                    key: 'group',
                    label: initialState?.enterprise?.shot_name || initialState?.enterprise?.name,
                    children:
                        initialState?.enterpriseList?.map(({enterprise, staff}, index) => ({
                            key: enterprise.id,
                            label: (
                                <SubMenuRender
                                    key={index}
                                    groupName={enterprise.shot_name || enterprise.name}
                                    manager={staff.name}
                                    selected={enterprise?.id === initialState?.enterprise?.id}
                                />
                            ),
                            onClick: async () => {
                                const {data} = await changeEnterprise({
                                    values: {
                                        target_enterprise_id: enterprise.id,
                                        callback: window.location.href,
                                    },
                                })
                                const redirectUrl = data?.callback
                                if (redirectUrl) {
                                    window.location.href = redirectUrl
                                }
                            },
                        })) || [],
                },
                {
                    type: 'divider',
                },
                {
                    key: 'manageApp',
                    label: <div style={{color: '#1890FF'}}>管理团队应用</div>,
                    onClick: () => {
                        if (accessOrderProductsFromAppMarket) {
                            return window.open(
                                `${ENTERPRISE_BASE_URL}/account/orders/orderProducts`,
                                '_blank'
                            )
                        }
                        message.error('无此页面的权限，请联系团队管理员')
                    },
                },
                {
                    key: 'userinfo',
                    label: <div style={{color: '#1890FF'}}>账户信息</div>,
                    onClick: () => {
                        window.location.href = `${ENTERPRISE_BASE_URL}/userMsg`
                    },
                },
                {
                    key: 'logout',
                    label: <div style={{color: '#FF4D4F'}}>退出登录</div>,
                    onClick: async () => {
                        try {
                            await logout()
                            window.location.reload()
                            message.success('已退出登录', 1, () => refresh())
                        } catch (error) {
                            message.error('退出登录失败')
                        }
                    },
                },
            ])
        }
    }, [isLogin])

    return (
        <>
            {isLogin ? (
                <>
                    <a
                        className="header_right_icon"
                        data-testid="user-profile-header-right-icon"
                        href={ENTERPRISE_BASE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={`https://cdn-static.xiaoduoai.com/apphub/bitable/1b62d09a3e1b4f012d4f26c778cc514e.png`}
                            alt="logo"
                            style={{marginRight: 8}}
                        />
                        进入晓总管
                    </a>
                    <Dropdown
                        overlayClassName="header_right_user_dropdown"
                        placement="bottomRight"
                        menu={{items: userDropDownItems}}
                        destroyPopupOnHide
                    >
                        <a
                            onClick={(e) => e.preventDefault()}
                            style={{color: 'black'}}
                            className="header_right_user_dropdown_btn"
                            data-testid="user-profile-header-right-user-dropdown-btn"
                        >
                            <div style={{display: 'flex', alignItems: 'center', cursor:"pointer"}}>
                                <span
                                    className="header_right_user_dropdown_btn_label"
                                    data-testid="user-profile-header-right-user-dropdown-btn-label"
                                >
                                  {initialState?.user_account?.username}
                                </span>
                                <i className="iconfont" style={{color: '#999999',}}>&#xe801;</i>
                            </div>
                        </a>
                    </Dropdown>
                </>
            ) : (
                <a
                    className="header_right_login_resister"
                    data-testid="user-profile-header-right-login-resister"
                    href={`${ENTERPRISE_BASE_URL}/enuser/login?callback=${encodeURIComponent(pathname)}`}
                >
                    <i className="iconfont" style={{color: '#1890FF', marginRight: 8}}>&#xe874;</i>
                    登录/注册
                </a>
            )}
        </>
    )
}


export default UserProfile
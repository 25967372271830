import "./app-hub/store";
import "./app-hub/leaveContactInfo"
import "./app-hub/saveLocalStorage"
import "./app-hub/moreCategoryButton"
import "./app-hub/setProductCardStatus"
import "./app-hub/detail_tab_change"
import UserProfile from "./app-hub/components/UserProfile.jsx"
import HeaderMenuMobile from "./app-hub/components/HeaderMenuMobile.jsx"
import PayModal from "./app-hub/components/PayModal";
import DetailButtons from "./app-hub/components/DetailButtons.jsx";
import TopSearchInput from "./app-hub/components/TopSearchInput.jsx";
import SearchPageInput from "./app-hub/components/SearchPageInput.jsx";
import MobileSearchInput from "./app-hub/components/MobileSearchInput";
import RecordLeadModal from "./app-hub/components/RecordLeadModal";
import ImageViewer from "./app-hub/ImageViewer";
import Paid from "./app-hub/Paid";
import HotBuy from "./app-hub/components/HotBuy";

function safelyRender(component, elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        const root = ReactDOM.createRoot(element);
        root.render(component);
    } else {
        console.warn(`Element with ID ${elementId} not found. Component will not be rendered.`);
    }
}

safelyRender(<UserProfile/>, 'user_profile_container');
safelyRender(<HeaderMenuMobile/>, 'header_menu_mobile');
safelyRender(<DetailButtons/>, "app-hub-pc-buttons")
safelyRender(<DetailButtons shouldBind={false}/>, "app-hub-mobile-buttons")
safelyRender(<PayModal/>, "app-hub-pay-modal")
safelyRender(<TopSearchInput/>, "header-right-search")
safelyRender(<SearchPageInput/>, "app-hub-search-input")
safelyRender(<MobileSearchInput load={true}/>, "app-hub-search-mobile")
safelyRender(<MobileSearchInput load={false}/>, "app-hub-search-mobile-app")
safelyRender(<RecordLeadModal/>, "app-hub-record-lead-modal")
safelyRender(<ImageViewer/>, "app-hub-image-viewer")
safelyRender(<Paid/>, "paid-page")
safelyRender(<HotBuy/>, "hot-buy")

window.addEventListener('DOMContentLoaded', (event) => {
    const menu_list_btn_category_list_mobile_btn = document.getElementById("menu_list_btn_category_list_mobile_btn")
    const menu_list_btn_category_list = document.getElementById("menu_list_btn_category_list")
    const menu_list_btn_category_list_mobile_btn_icon = document.getElementById("menu_list_btn_category_list_mobile_btn_icon")
    const body = document.querySelector("body")
    if (menu_list_btn_category_list_mobile_btn){
        menu_list_btn_category_list_mobile_btn.addEventListener("click",(e)=>{
            if (menu_list_btn_category_list.style.display === "none"){
                e.stopPropagation();
                menu_list_btn_category_list.style.display = "block"
                menu_list_btn_category_list_mobile_btn_icon.innerText = "\ue802"
            }else {
                menu_list_btn_category_list.style.display = "none"
                menu_list_btn_category_list_mobile_btn_icon.innerText = "\ue801"
            }
        })
        // menu_list_btn_category_list.addEventListener("click", (e) => {
        //     e.stopPropagation();
        // });
        body.addEventListener("click",()=>{
            menu_list_btn_category_list.style.display = "none"
            menu_list_btn_category_list_mobile_btn_icon.innerText = "\ue801"
        })
    }
});
import store from '../store'

const { useSyncExternalStore } = React

export const useReduxStore = () => {
  return useSyncExternalStore(store.subscribe, () => store.getState())
}

export const useDispatch = () => {
  return store.dispatch
}

const sensors = sensorsDataAnalytic201505
const sensorsInit = () => {
    sensors.init({
        server_url: `https://wangcai.xiaoduoai.com/log/event_tracking`,
        heatmap_url: 'https://static.sensorsdata.cn/sdk/1.14.5/heatmap.min.js',
        heatmap: {
            //是否开启点击图。default 表示开启，自动采集 $WebClick 事件；'not_collect' 表示关闭
            clickmap: 'default',
            //是否开启触达注意力图。default 表示开启，自动采集 $WebStay 事件；'not_collect' 表示关闭
            scroll_notice_map: 'default',
        },
        is_track_single_page: true,
        use_client_time: true,
        show_log: false,
        send_type: 'ajax',
        cross_subdomain: false,
    })

    sensors.registerPage({
        app_id: 'xd052',
    })

    window.addEventListener('load', () => {
        if (!window.performance || process.env.NODE_ENV === 'development') return
        const { timing } = window.performance
        sensors.track('performance', {
            //白屏时间
            blank_time: timing.domContentLoadedEventEnd - timing.fetchStart,
            //首屏时间
            index_time: timing.domComplete - timing.fetchStart,
        })
    })

    return sensors
}

export { sensorsInit }
